

.div1:hover{
    background-color:#A1D683;
    border-radius:50%;

  }


  .div2:hover{
      background-color:#DD8A28;
      border-radius:50%
    }

      
  
    #link-menu a:hover{ 
     color:white;
      background-color:#483729;
      border-radius:50%;
      
        
    }
 
  .div4:hover{
      background-color:#619656;
      border-radius:50%
    }
    

  
  .div5:hover{
      background-color:#3C95DF;
      border-radius:50%
    }
    

  .div6:hover{
      background-color:#CACE36;
      border-radius:50%
    }
