/*
	Max width before this PARTICULAR table gets nasty. This query will take effect for any screen smaller than 760px and also iPads specifically.
	*/
	@media
	  only screen 
    and (max-width: 760px), (min-device-width: 768px) 
    and (max-device-width: 1024px)  {

		/* Force table to not be like tables anymore */
		table, thead, tbody, th, td, tr {
			display: block;
		}

		/* Hide table headers (but not display: none;, for accessibility) */
		thead tr {
			/* position: absolute; */
			top: -9999px;
			left: -9999px;
		}

    tr {
      margin: 0 0 1rem 0;
    }
      
    tr:nth-child(odd) {
      background: #ccc;
    }
    
		td {
			/* Behave  like a "row" */
			border: none;
			border-bottom: 1px solid #eee;
			position: relative;
			/* padding-left: 50%; */
		}

		td:before {
			/* Now like a table header */
			/* position: absolute; */
			/* Top/left values mimic padding */
			top: 0;
			left: 6px;
			/* width: 45%; */
			padding-right: 10px;
			white-space: nowrap;
		}
.TableHidden{
		visibility:hidden ;
	
}
		/*
		Label the data
    You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
		*/
		td:nth-of-type(1):before { content: "Imagen";  text-align: left; font-weight: bold;}
		td:nth-of-type(2):before { content: "Nombre del Producto"; text-align: left; font-weight: bold; }
		td:nth-of-type(3):before { content: "Cantidad"; text-align: left; font-weight: bold; }
		td:nth-of-type(4):before { content: "Precio Unitario"; text-align: left;  font-weight: bold;}
        td:nth-of-type(5):before { content: "SubTotal";  text-align: left; font-weight: bold;}
    }
    
  