.sticky {
	position: fixed;
	top: 0;
	width: 100%;
	background-color: red;
  }
.main-section{
	background-color: black;
	margin-top:50px;
}
/* // .dropdown{
// 	float:right;
// 	padding-right: 30px;
// } */
.btn{
	border:0px;
	margin:10px 0px;
	box-shadow:none !important; 
}
.dropdown .dropdown-menu{
	padding:20px;
	top:30px !important;
	width:350px !important;
	left:-110px !important;
	/* box-shadow:0px 5px 30px black; */
}
.total-header-section{
	border-bottom:1px solid black;
}
.total-section p{
	margin-bottom:20px;
}
.cart-detail{
	padding:15px 0px;
}
.cart-detail-img img{
	width:100%;
	height:100%;
	padding-left:15px;
}
.cart-detail-product p{
	margin:0px;
	color:black;
	font-weight:500;
}
.cart-detail .price{
	font-size:12px;
	margin-right:10px;
	font-weight:500;
	color:black;
}
.cart-detail .count{
	color:black;
}
.checkout{
	border-top:1px solid black;	
	padding-top: 15px;
}
.checkout .btn-primary{
	border-radius:50px;
	height:50px;
}
.dropdown-menu:before{
  content: " ";
  position:absolute;
  top:-20px;
  right:50px;
  border:10px solid transparent;
  border-bottom-color:black; 
}

.dropbtn {
  background-color:white;
  color: black;
  padding: 16px;
  font-size: 16px;
  border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  max-width: 450px;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  /* //display: none; */
  position: absolute;
  background-color:white;
  min-width: 200px;
  /* //box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
  z-index: 1;

    padding:20px;
	top:50px !important;
	width:400px !important;
	/* left:-250px !important; */
	right: 10%;
	box-shadow:0px 5px 30px black;
}

.dropdown-content2 {
	/* //display: none; */
	position: absolute;
	background-color:#013356;
	min-width: 200px;
	/* //box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
	z-index: 2;
  
	  padding:20px;
	  top:50px !important;
	  width:350px !important;
	  left:-250px !important;
	  box-shadow:0px 5px 30px black;
  }
/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: Black;}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {background-color: #598059;}
div.ex3 {
	
	/* //width: 110px; */
	max-height: 300px;
	max-width: 100%;
	/* //overflow: auto; */
	overflow: auto;
	overflow-x: hidden;
	
	overflow-y: visible;
	
  }
  .icon-text {
    display: flex;
    align-items: center;
}